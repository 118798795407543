<template>
  <div v-if="pageContent" class="thank-you">
    <GenericImageLayout
      v-editable="pageContent"
      :image-src="pageContent.image.filename"
      mobile-image
    >
      <div class="thank-you__text-wrapper section">
        <h1 class="thank-you__title h2">{{ pageContent.title }}</h1>
        <Article
          v-if="isAreaSupported"
          class="thank-you__copy"
          v-bind="cmsBlockToProps(pageContent.nextStepsForCoveredArea)"
          :variable-set="contentVariables"
        />
        <Article
          v-else-if="isFlatRoof"
          class="thank-you__copy"
          v-bind="cmsBlockToProps(pageContent.nextStepsForFlatRoof)"
          :variable-set="contentVariables"
        />
        <Article
          v-else
          class="thank-you__copy mt-7"
          v-bind="cmsBlockToProps(pageContent.nextStepsForOutsideArea)"
          :variable-set="contentVariables"
        />
        <Article
          v-if="isAreaSupported"
          ref="virtualSurveyText"
          class="thank-you__copy mt-7"
          v-bind="cmsBlockToProps(pageContent.virtualSurveyText)"
        />
        <AppButton
          v-if="isAreaSupported"
          ref="virtualSurveyButton"
          class="thank-you__button"
          :href="pageContent.virtualSurveyLink"
        >
          {{ pageContent.virtualSurveyButton }}
        </AppButton>
        <Article
          v-if="isAreaSupported"
          ref="bookCallText"
          class="thank-you__copy mt-7"
          v-bind="cmsBlockToProps(pageContent.bookCallText)"
        />
        <AppButton
          v-if="isAreaSupported"
          ref="bookCallLink"
          class="thank-you__button"
          :href="pageContent.bookCallLink"
        >
          {{ pageContent.bookCallButton }}
        </AppButton>
      </div>
    </GenericImageLayout>
    <ContentBlockList :blocks="pageContent.body" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import GenericImageLayout from "@soenergy/frontend-library/src/components/GenericImageLayout.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import ContentBlockList from "@soenergy/frontend-library/src/components/ContentBlockList.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import { EMAIL } from "../variable_keys/Quote"
import { mapGetters } from "vuex"

import story from "soenergy-cms-loader!?path=solar/thank-you"

export interface SolarQuoteThankYou {
  title: string
  image: ImageUrl
  nextStepsForCoveredArea: object
  nextStepsForOutsideArea: object
  nextStepsForFlatRoof: object
  virtualSurveyText: object
  virtualSurveyButton: string
  virtualSurveyLink: string
  bookCallText: object
  bookCallButton: string
  bookCallLink: string
  body: Array<object>
}

export interface ImageUrl {
  filename: string
}

export default defineComponent({
  components: {
    ContentBlockList,
    GenericImageLayout,
    Article,
    AppButton,
  },
  mixins: [cmsPreviewMixin<SolarQuoteThankYou>({ story })],
  computed: {
    ...mapGetters({
      email: "email",
      isFlatRoof: "isFlatRoof",
    }),
    isAreaSupported() {
      return this.$route.query.covered === "true"
    },
    contentVariables() {
      return {
        [EMAIL]: this.email,
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.thank-you {
  &__text-wrapper {
    padding: $space-10 $space-6;

    @include lg {
      padding: $space-14 $space-16 $space-10 0;
    }
  }
  &__title {
    text-transform: uppercase;
    margin-bottom: $space-8;
    font-weight: $weight-bold;

    @include md {
      margin-bottom: $space-12;
    }
  }
  &__copy {
    font-size: $font-size-3;
    &--bold {
      font-weight: $weight-medium;
    }
  }
  &__button {
    width: 100%;

    @include lg {
      width: auto;
    }
  }
}
</style>
