import type { VueCookies } from "vue-cookies"

const CONECTIA_COOKIE_EXPIRATION_TIME = "30d"
const CONECTIA_PARAM_VALUE = "conectia"
const CONECTIA_REQUEST_ID_KEY = "reqid"
const CONECTIA_AFFILIATE_ID_KEY = "affid"

export const CONECTIA_COOKIE_NAME = "conectia_solar_v2"

export default {
  init(cookieManager: VueCookies) {
    this.cookieManager = cookieManager
    const url = new URL(window.location.href)

    if (this.hasValidConectiaParams(url)) {
      const utm = this.extractUtmParamsFromUrl(url)
      const conectiaData = {
        affiliate: {
          conectiaReqid: url.searchParams.get(CONECTIA_REQUEST_ID_KEY),
          conectiaAffid: url.searchParams.get(CONECTIA_AFFILIATE_ID_KEY),
        },
        utm,
      }

      this.cookieManager.set(
        CONECTIA_COOKIE_NAME,
        conectiaData,
        CONECTIA_COOKIE_EXPIRATION_TIME
      )
    }
  },
  hasValidConectiaParams({ searchParams }: URL) {
    try {
      return (
        searchParams.has("name") &&
        searchParams.get("name") === CONECTIA_PARAM_VALUE &&
        searchParams.has(CONECTIA_AFFILIATE_ID_KEY) &&
        searchParams.has(CONECTIA_REQUEST_ID_KEY)
      )
    } catch (e) {
      return false
    }
  },
  extractUtmParamsFromUrl({ searchParams, origin }: URL) {
    return {
      source: searchParams.get("utm_source"),
      id: searchParams.get("utm_id"),
      medium: searchParams.get("utm_medium"),
      name: searchParams.get("utm_name"),
      term: searchParams.get("utm_term"),
      content: searchParams.get("utm_content"),
      gclid: searchParams.get("gclid"),
      url: origin,
    }
  },
}
