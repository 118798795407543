import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import { getDefaultState } from "../"
import SolarQuoteApiService from "../../services/SolarQuoteApiService"
import latLngToCoordinates from "../../helpers/latLngToCoordinates"
import formatAccountsAddress from "../../helpers/formatAccountsAddress"
import UserStatusApiService from "@soenergy/frontend-library/src/services/UserStatusService/UserStatusApiService"
import CustomerDataApiService from "../../services/CustomerDataApiService"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

export const untestedGetters = {
  getIsUnitRateExact(state) {
    return state.quote.isUnitRateExact
  },
  getUnitRate(state) {
    return state.quote.unitRate
  },
  getWhenGetSolar(state) {
    return state.quote.whenGetSolar
  },
  getWhoSuggested(state) {
    return state.quote.whoSuggested
  },
  getCustomerPreference(state) {
    return state.quote.customerPreference
  },
  getWhenAtHome(state) {
    return state.quote.whenAtHome
  },
  getAddress(state) {
    return state.quote.address.address
  },
  postcode(state) {
    return state.quote.postcode
  },
  address(state) {
    return state.quote.address ? state.quote.address.address : null
  },
  roofLocation(state) {
    return state.quote.roofLocation
  },
  extraInfo(state) {
    return state.quote.extraInfo
  },
  accountNumber(state) {
    return state.quote.accountNumber
  },
  fullName(state) {
    return `${state.quote.firstName} ${state.quote.lastName}`
  },
  firstName(state) {
    return state.quote.firstName
  },
  lastName(state) {
    return state.quote.lastName
  },
  title(state) {
    return state.quote.title
  },
  email(state) {
    return state.quote.email
  },
  isFlatRoof(state) {
    return state.quote.isFlatRoof
  },
  getEstimatedUsage(state) {
    return state.quote.estimatedUsage
  },
  electricitySupplier(state) {
    return state.quote.electricitySupplier
  },
  electricitySupplierTariff(state) {
    return state.quote.electricitySupplierTariff
  },
  electricitySuppliersList(state) {
    return state.currentSupplierTariff.electricitySuppliersList
  },
  electricitySupplierTariffList(state) {
    return state.currentSupplierTariff.electricitySupplierTariffList
  },
  isLoggedIn() {
    return AuthenticationService.isAuthenticated()
  },
  getCustomerAccounts(state) {
    return state.customerAccount.accounts
  },
  getAccountNumber(state) {
    return state.quote.accountNumber
  },
  getTariff(state) {
    return state.quote.tariff
  },
  getSolarPackage(state) {
    return state.quote.solarPackage
  },
  getSolarProductType(state) {
    return state.quote.solarProductType
  },
  getSurveyorId(state) {
    return state.quote.surveyorId
  },
  getVoucherCode(state) {
    return state.quote.voucherCode
  },
}

export const untestedMutation = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_IS_UNIT_RATE_EXACT(state, isExact) {
    state.quote.isUnitRateExact = isExact
  },
  SET_UNIT_RATE(state, { value, fromApi }) {
    state.quote.unitRate = { ...state.quote.unitRate, value, fromApi }
  },
  SET_IS_EXISTING_CUSTOMER(state, isCustomer) {
    state.quote.isExistingCustomer = isCustomer
  },
  SET_FLAT_ROOF(state, isFlatRoof) {
    state.quote.isFlatRoof = isFlatRoof
  },
  SET_SOLAR_PACKAGE(state, value) {
    state.quote.solarPackage = value.packageId
    state.quote.solarPackageTitle = value.title
  },
  SET_SOLAR_PRODUCT_TYPE(state, value) {
    state.quote.solarProductType = value
  },
  SET_ESTIMATED_USAGE(state, { value, fromApi }) {
    state.quote.estimatedUsage = {
      ...state.quote.estimatedUsage,
      value,
      fromApi,
    }
  },
  SET_EXACT_USAGE_KWH(state, exactUsageKwh) {
    state.quote.exactUsageKwh = exactUsageKwh
  },
  SET_IS_USAGE_EXACT(state, isUsageExact) {
    state.quote.isUsageExact = isUsageExact
  },
  SET_CONSERVATION_AREA(state, isInConservationArea) {
    state.quote.isInConservationArea = isInConservationArea
  },
  SET_WHEN_AT_HOME(state, value) {
    state.quote.whenAtHome = value
  },
  SET_WHEN_GET_SOLAR(state, value) {
    state.quote.whenGetSolar = value
  },
  SET_WHO_SUGGESTED(state, value) {
    state.quote.whoSuggested = value
  },
  SET_CUSTOMER_PREFERENCE(state, value) {
    state.quote.customerPreference = value
  },
  SET_ELECTRICITY_SUPPLIER(state, supplier) {
    state.quote.electricitySupplier = supplier
  },
  SET_ELECTRICITY_SUPPLIER_TARIFF(state, tariff) {
    state.quote.electricitySupplierTariff = tariff
  },
  SET_ELECTRICITY_SUPPLIER_LIST(state, suppliers) {
    state.currentSupplierTariff.electricitySuppliersList = suppliers
  },
  SET_ELECTRICITY_SUPPLIER_TARIFF_LIST(state, tariffs) {
    state.currentSupplierTariff.electricitySupplierTariffList = tariffs
  },
  SET_FIRST_NAME(state, firstName) {
    state.quote.firstName = firstName
  },
  SET_LAST_NAME(state, lastName) {
    state.quote.lastName = lastName
  },
  SET_TITLE(state, title) {
    state.quote.title = title
  },
  SET_EMAIL(state, email) {
    state.quote.email = email
  },
  SET_PHONE(state, phone) {
    state.quote.phone = phone
  },
  SET_DATA_CONSENT(state, dataConsent) {
    state.quote.dataConsent = dataConsent
  },
  SET_PROPERTY_CONFIRMATION(state, propertyConfirmation) {
    state.quote.propertyConfirmation = propertyConfirmation
  },
  SET_POSTCODE(state, postcode) {
    state.quote.postcode = postcode
  },
  SET_ADDRESS_LINES(state, address) {
    state.quote.address = {
      ...state.quote.address,
      address,
    }
  },
  SET_EXTRA_INFO(state, extraInfo) {
    state.quote.extraInfo = extraInfo
  },
  SET_ROOF_LOCATION(state, location) {
    state.quote.roofLocation = location
  },
  SET_G_CAPTCHA_RESPONSE(state, response) {
    state.gCaptcha.gCaptchaResponse = response
  },
  SET_CUSTOMER_ACCOUNTS(state, response) {
    state.customerAccount = response
  },
  SET_OVERRIDE_ADDRESS(state, overrideAddress) {
    state.quote.overrideAddress = overrideAddress
  },
  SET_TARIFF(state, tariff) {
    state.quote.tariff = tariff
  },
  SET_ACCOUNT_NUMBER(state, accountNumber) {
    state.quote.accountNumber = accountNumber
  },
  SET_SURVEYOR_ID(state, surveyorId) {
    state.quote.surveyorId = surveyorId
  },
  SET_VOUCHER_CODE(state, voucherCode) {
    state.quote.voucherCode = voucherCode
  },
}

export const untestedActions = {
  async submitProspectDetails({ state }, utmCookie) {
    const quote = state.quote
    const config = {
      headers: {
        "g-recaptcha-response": state.gCaptcha.gCaptchaResponse,
      },
    }

    return SolarQuoteApiService.createQuoteRequest(
      {
        postcode: quote.postcode!,
        first_name: quote.firstName!,
        last_name: quote.lastName!,
        title: quote.title!,
        email: quote.email!,
        phone: quote.phone!.replace(/\s/g, ""),
        consent_for_data_processing: quote.dataConsent!,
        account_number: quote.accountNumber!,
        flat_roof: quote.isFlatRoof!,
        utm: utmCookie,
        customer_preference: quote.customerPreference?.value || null,
        who_suggested: quote.whoSuggested?.value || null,
        voucher_code: quote.voucherCode || null,
        surveyor_id: quote.surveyorId || null,
      },
      config
    )
  },
  async submitQuote({ state, getters }, cookieData) {
    const quote = state.quote
    const config = {
      headers: {
        "g-recaptcha-response": state.gCaptcha.gCaptchaResponse,
      },
    }

    if (!quote.address?.address) {
      ErrorReporter.report("Solar submitQuote: no address provided")
    }

    return SolarQuoteApiService.createQuoteRequest(
      {
        postcode: quote.postcode!,
        first_name: quote.firstName!,
        last_name: quote.lastName!,
        title: quote.title!,
        email: quote.email!,
        phone: quote.phone!.replace(/\s/g, ""),
        consent_for_data_processing: quote.dataConsent!,
        account_number: quote.accountNumber!,
        flat_roof: quote.isFlatRoof!,
        serviceable_prospect_data: {
          address_lines: quote.address?.address,
          coordinates: latLngToCoordinates(quote.roofLocation),
          notes: quote.extraInfo!,
          is_existing_customer: getters.isLoggedIn
            ? true
            : quote.isExistingCustomer!,
          lives_in_conservation_area: quote.isInConservationArea!,
          annual_elec_consumption:
            quote.exactUsageKwh! || quote.estimatedUsage.value!,
          unit_rate: quote.unitRate.value!,
          selected_package: quote.solarPackage!,
          product_type: quote.solarProductType,
          time_indoors: quote.whenAtHome!,
          installation_timeframe: quote.whenGetSolar?.value || "",
          authorised_to_alter_property: quote.propertyConfirmation!,
          tariff: quote.tariff!,
        },
        utm: cookieData?.utmCookie,
        customer_preference: quote.customerPreference?.value || null,
        who_suggested: quote.whoSuggested?.value || null,
        conectia_data: {
          req_id: cookieData?.affiliateCookie?.conectiaReqid || null,
          aff_id: cookieData?.affiliateCookie?.conectiaAffid || null,
        },
        voucher_code: quote.voucherCode || null,
        surveyor_id: quote.surveyorId || null,
      },
      config
    )
  },
  login({ state }, credentials) {
    return AuthenticationService.login(credentials)
  },
  async storeCustomerDetails({ state, commit, dispatch }, details) {
    const { email, phone_number, title, first_name, last_name } = details
    formatAccountsAddress(details.accounts)
    commit("SET_FIRST_NAME", first_name)
    commit("SET_LAST_NAME", last_name)
    commit("SET_TITLE", title)
    commit("SET_EMAIL", email)
    commit("SET_PHONE", phone_number)
    if (details.accounts.length === 1) {
      dispatch("updateSelectedAccount", details.accounts[0])
    }
    commit("SET_CUSTOMER_ACCOUNTS", details)
  },
  updateSelectedAccount({ commit, dispatch }, account) {
    dispatch("validateData", {
      mutation: "SET_UNIT_RATE",
      value: account.unit_rate_day,
    })
    dispatch("validateData", {
      mutation: "SET_ESTIMATED_USAGE",
      value: account.electricity_consumption,
    })
    commit("SET_TARIFF", account.tariff)
    commit("SET_POSTCODE", account.address.postcode)
    commit("SET_ADDRESS", { address: account.longAddress })
  },
  async fetchJuniferAccountNumber({ commit, dispatch }) {
    const juniferData = await UserStatusApiService.getUserStatusWithToken()
    commit("SET_ACCOUNT_NUMBER", juniferData.data.juniferAccountNumber)
  },
  async fetchCustomerAccountDetails({ state, dispatch }) {
    const accountDetails = await CustomerDataApiService.getCustomerData(
      state.quote.accountNumber
    )
    await dispatch("storeCustomerDetails", accountDetails.data)
  },
  validateData({ state, commit }, { value, mutation }) {
    if (!value) {
      commit(mutation, { fromApi: false })
    } else {
      commit(mutation, {
        fromApi: true,
        value,
      })
    }
  },
}
