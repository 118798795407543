<template>
  <section v-if="pageContent" class="contact-details">
    <h1 class="contact-details__title h4">{{ pageContent.title }}</h1>
    <Combobox
      ref="title-combobox"
      v-model="title"
      input-id="select-title"
      :options="['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr']"
      label="Title"
      class="mb-4 contact-details__title-select form-group__input-group"
    />
    <InputField
      id="firstName"
      ref="first-name-input"
      v-model="firstName"
      name="firstName"
      label="First name"
      :error-message="
        v$.firstName.$error ? pageContent.firstNameInputFieldErrorMessage : null
      "
      :has-error="v$.firstName.$error"
      @blur="v$.firstName.$touch()"
    />
    <InputField
      id="lastName"
      ref="last-name-input"
      v-model="lastName"
      name="lastName"
      label="Last name"
      :error-message="
        v$.lastName.$error ? pageContent.lastNameInputFieldErrorMessage : null
      "
      :has-error="v$.lastName.$error"
      @blur="v$.lastName.$touch()"
    />
    <InputField
      id="email"
      ref="email-input"
      v-model="email"
      name="email"
      label="Email"
      :error-message="
        v$.email.$error ? pageContent.emailInputFieldErrorMessage : null
      "
      :has-error="v$.email.$error"
      @blur="v$.email.$touch()"
    />
    <InputField
      id="phone"
      ref="phone-input"
      v-model="phone"
      name="phone"
      label="Phone"
      :error-message="
        v$.phone.$error ? pageContent.phoneInputFieldErrorMessage : null
      "
      :has-error="v$.phone.$error"
      @blur="v$.phone.$touch()"
    />
    <Checkbox
      v-model="dataConsent"
      class="contact-details__checkbox"
      input-id="data-consent"
      is-small
    >
      <Article :text-content="pageContent.dataConsentCheckboxCopy" no-margin />
    </Checkbox>
    <Checkbox
      v-model="propertyConfirmation"
      class="contact-details__checkbox"
      input-id="property-confirmation"
      is-small
    >
      <Article
        :text-content="pageContent.propertyConfirmationCheckboxCopy"
        no-margin
      />
    </Checkbox>
    <div v-if="!recaptchaDisabled" class="contact-details__recaptcha-wrapper">
      <Recaptcha
        ref="recaptcha"
        :sitekey="siteKey"
        @success="recaptchaSuccess"
      ></Recaptcha>
    </div>
    <AppButton
      class="contact-details__next-button"
      full-width
      size="large"
      :disabled="
        !dataConsent ||
        !propertyConfirmation ||
        !(recaptchaSucceded || recaptchaDisabled)
      "
      :loading="loading"
      @click="submit"
    >
      {{ pageContent.nextStepButtonCopy }}
    </AppButton>
    <FeedbackDialog
      v-if="quoteError"
      class="contact-details__feedback-dialog"
      :message="pageContent.submissionError"
      variant="negative"
    />
  </section>
</template>

<script lang="ts">
import { ref, computed, defineComponent, inject } from "vue"
import { useStore } from "@/store"
import useVuelidate from "@vuelidate/core"
import { required, email as emailValidator } from "@vuelidate/validators"
import Recaptcha from "@soenergy/frontend-library/src/components/Recaptcha.vue"
import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import Checkbox from "@soenergy/frontend-library/src/components/Checkbox.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import Combobox from "@soenergy/frontend-library/src/components/Combobox.vue"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

import story from "soenergy-cms-loader!?path=solar/contact-details"

import { VueCookies } from "vue-cookies"
import { UTM_COOKIE_NAME } from "@/services/UtmService"
import { CONECTIA_COOKIE_NAME } from "@/services/ConectiaService"

export interface SolarQuoteContactDetails {
  title: string
  firstNameInputFieldErrorMessage: string
  lastNameInputFieldErrorMessage: string
  emailInputFieldErrorMessage: string
  phoneInputFieldErrorMessage: string
  dataConsentCheckboxCopy: object
  propertyConfirmationCheckboxCopy: object
  nextStepButtonCopy: string
  submissionError: string
}

export default defineComponent({
  components: {
    InputField,
    Checkbox,
    AppButton,
    Article,
    FeedbackDialog,
    Recaptcha,
    Combobox,
  },
  mixins: [cmsPreviewMixin<SolarQuoteContactDetails>({ story })],
  emits: ["go-forward"],
  setup(props, context) {
    const store = useStore()
    const $cookies = inject<VueCookies>("$cookies")
    const phoneNumberValidator = (value) => {
      const trimmedNumber = value.replace(/\s/g, "")
      return Number.isInteger(+trimmedNumber) && trimmedNumber.length >= 9
    }

    const firstName = computed({
      get() {
        return store.state.quote.firstName
      },
      set(value) {
        store.commit("SET_FIRST_NAME", value)
      },
    })

    const lastName = computed({
      get() {
        return store.state.quote.lastName
      },
      set(value) {
        store.commit("SET_LAST_NAME", value)
      },
    })

    const title = computed({
      get() {
        return store.state.quote.title
      },
      set(value) {
        store.commit("SET_TITLE", value)
      },
    })
    const email = computed({
      get() {
        return store.state.quote.email
      },
      set(value) {
        store.commit("SET_EMAIL", value)
      },
    })
    const phone = computed({
      get() {
        return store.state.quote.phone
      },
      set(value) {
        store.commit("SET_PHONE", value)
      },
    })
    const dataConsent = computed({
      get() {
        return store.state.quote.dataConsent
      },
      set(value) {
        store.commit("SET_DATA_CONSENT", value)
      },
    })
    const propertyConfirmation = computed({
      get() {
        return store.state.quote.propertyConfirmation
      },
      set(value) {
        store.commit("SET_PROPERTY_CONFIRMATION", value)
      },
    })
    const rules = computed(() => {
      return {
        firstName: { required },
        lastName: { required },
        email: { required, emailValidator },
        phone: { required, phoneNumberValidator },
        dataConsent: { required },
        propertyConfirmation: { required },
      }
    })

    function recaptchaSuccess(response) {
      recaptchaSucceded.value = true
      store.commit("SET_G_CAPTCHA_RESPONSE", response)
    }

    function getUtmCookie() {
      const conectiaCookie = $cookies?.get(CONECTIA_COOKIE_NAME)
      if (conectiaCookie) return conectiaCookie.utm

      const utmCookie = $cookies?.get(UTM_COOKIE_NAME)
      if (utmCookie && utmCookie.source !== "conectia") return utmCookie
    }

    function getAffiliateCookie() {
      return $cookies?.get(CONECTIA_COOKIE_NAME)?.affiliate
    }

    function getCookies() {
      const utmCookie = getUtmCookie()
      const affiliateCookie =
        utmCookie?.source === "conectia" ? getAffiliateCookie() : undefined
      return { utmCookie, affiliateCookie }
    }

    const v$ = useVuelidate(rules, {
      firstName,
      lastName,
      email,
      phone,
      dataConsent,
      propertyConfirmation,
    })
    const loading = ref(false)
    const quoteError = ref(false)
    const submit = () => {
      if (v$.value.$invalid) {
        v$.value.$touch()
      } else {
        loading.value = true
        quoteError.value = false
        const { utmCookie, affiliateCookie } = getCookies()
        store
          .dispatch("submitQuote", { utmCookie, affiliateCookie })
          .then((response) => {
            context.emit("go-forward")
            $cookies?.remove(UTM_COOKIE_NAME)
            $cookies?.remove(CONECTIA_COOKIE_NAME)
          })
          .catch((err) => {
            quoteError.value = true
            ErrorReporter.report(err, { context: "solar submitQuote failed" })
          })
          .finally(() => {
            loading.value = false
          })
      }
    }

    const siteKey = computed(() => {
      return process.env.VUE_APP_RECAPTCHA_KEY
    })

    const recaptchaDisabled = computed(() => {
      return process.env.VUE_APP_RECAPTCHA_DISABLED === "true"
    })

    let recaptchaSucceded = ref(false)
    let recaptchaKey = ref(null)

    return {
      firstName,
      lastName,
      title,
      email,
      phone,
      dataConsent,
      propertyConfirmation,
      v$,
      loading,
      quoteError,
      submit,
      siteKey,
      recaptchaSucceded,
      recaptchaKey,
      recaptchaSuccess,
      recaptchaDisabled,
    }
  },
})
</script>

<style lang="scss" scoped>
.contact-details {
  &__title {
    text-transform: unset;
  }

  &__checkbox {
    margin-top: $space-6;
  }

  &__next-button {
    margin-top: $space-8;
  }

  &__feedback-dialog {
    margin-top: $space-4;
  }

  &__recaptcha-wrapper {
    margin-top: $space-6;
  }
  &__title-select {
    background-color: $white;
  }
}
</style>
