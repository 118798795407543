<template>
  <LoadingOverlay :is-active="loading" />
  <div v-if="pageContent && accountsData.accounts" class="customer-details">
    <div v-if="accountsData.accounts.length > 1 && !address">
      <h1 class="customer-details__title h4">
        {{ pageContent.selectAccountTitle }}
      </h1>
      <p class="customer-details__description">
        {{ pageContent.selectAccountDescription }}
      </p>
      <Combobox
        ref="address-combobox"
        v-model="addressValue"
        input-id="select-account"
        :options="customerAccountsAddress"
        label="Select Your Address"
      />
      <AppButton
        class="customer-details__next-button"
        :disabled="!addressValue"
        full-width
        @click="submit"
      >
        <span>{{ pageContent.nextStepButton }}</span>
        <span class="customer-details__icon icon icon-arrow-right" />
      </AppButton>
      <p class="customer-details__description">
        {{ pageContent.multiAccountDetailsDescription }}
        <a @click="manualAddress">{{
          pageContent.multiAccountDetailsEnterAddress
        }}</a>
      </p>
    </div>
    <div v-else>
      <h1 class="customer-details__title h4">
        {{ pageContent.accountDetailsTitle }}
      </h1>
      <p class="customer-details__description">
        {{ pageContent.accountDetailsDescription }}
        <a @click="manualAddress">{{
          pageContent.accountDetailsEnterAddress
        }}</a>
      </p>
      <DetailsContainer ref="details-container" :customer-data="customerData" />
      <AppButton
        class="customer-details__next-button"
        full-width
        @click="checkPostcode"
      >
        <span>{{ pageContent.nextStepButton }}</span>
        <span class="customer-details__icon icon icon-arrow-right" />
      </AppButton>
    </div>
  </div>
  <div v-else-if="error" class="customer-details__error-message">
    <Article :text-content="pageContent.errorMessage" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import DetailsContainer from "@/components/DetailsContainer.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import Combobox from "@soenergy/frontend-library/src/components/Combobox.vue"
import store from "../../store"
import serveUserBasedOnPostcode from "@/helpers/serveUserBasedOnPostcode"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import LoadingOverlay from "@soenergy/frontend-library/src/components/LoadingOverlay.vue"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import story from "soenergy-cms-loader!?path=solar/customer-details"

interface SolarQuoteCustomerDetails {
  selectAccountTitle: string
  selectAccountDescription: string
  accountDetailsTitle: string
  accountDetailsDescription: string
  accountDetailsEnterAddress: string
  multiAccountDetailsDescription: string
  multiAccountDetailsEnterAddress: string
  nextStepButton: string
  errorMessage: string
}

export default defineComponent({
  components: {
    DetailsContainer,
    AppButton,
    Combobox,
    LoadingOverlay,
    Article,
  },
  mixins: [cmsPreviewMixin<SolarQuoteCustomerDetails>({ story })],
  emits: ["go-forward"],
  data() {
    return {
      addressValue: null,
      loading: true,
      error: false,
    }
  },
  computed: {
    ...mapGetters({
      unitRate: "getUnitRate",
      getAddress: "getAddress",
      estimatedUsage: "getEstimatedUsage",
      customerName: "name",
      firstName: "firstName",
      lastName: "lastName",
      title: "title",
      getCustomerAccounts: "getCustomerAccounts",
      loggedIn: "isLoggedIn",
      tariff: "getTariff",
      postcode: "postcode",
    }),
    ...mapState({
      accountsData: (state: any) => state.customerAccount,
    }),
    customerData() {
      return [
        {
          label: "Name",
          data: this.firstName,
          content: `${this.title || ""} ${this.firstName || ""} ${
            this.lastName || ""
          }`,
        },
        {
          label: "Address",
          data: this.getAddress,
          content: `${this.address} ${this.postcode}`,
        },
        { label: "Tariff", data: this.tariff, content: this.tariff },
        {
          label: "Electricity Unit Rate",
          data: this.unitRate.value,
          content: `${this.unitRate.value} p/kWh`,
        },
        {
          label: "Estimated Annual Usage",
          data: this.estimatedUsage.value,
          content: `${this.estimatedUsage.value} kWh`,
        },
      ]
    },
    address: {
      get() {
        return this.getAddress
      },
      set(value) {
        store.commit("SET_ADDRESS", { address: value })
      },
    },
    customerAccountsAddress() {
      return this.getCustomerAccounts.map((account) => account.longAddress)
    },
    account() {
      return this.getCustomerAccounts.find(
        (acc) => acc.longAddress === this.addressValue
      )
    },
  },
  async mounted() {
    await this.fetchCustomerDetails()
  },
  methods: {
    async fetchCustomerDetails() {
      this.setOverrideAddress(false)
      try {
        await this.fetchJuniferAccountNumber()
        await this.fetchCustomerAccountDetails()
      } catch (err) {
        this.error = true
        ErrorReporter.report(err, {
          context: "solar fetching customer data failed",
        })
      } finally {
        this.loading = false
      }
    },
    ...mapActions([
      "updateSelectedAccount",
      "fetchCustomerAccountDetails",
      "fetchJuniferAccountNumber",
    ]),
    ...mapMutations({
      setOverrideAddress: "SET_OVERRIDE_ADDRESS",
    }),
    async manualAddress() {
      await this.setOverrideAddress(true)
      this.$emit("go-forward")
    },
    submit() {
      this.updateSelectedAccount(this.account)
    },
    checkPostcode() {
      serveUserBasedOnPostcode(this.postcode)
    },
  },
})
</script>

<style lang="scss" scoped>
.customer-details {
  &__title {
    margin-bottom: $space-10;
    text-transform: none;
  }

  &__description {
    font-size: $font-size-4;
    margin-bottom: $space-10;
  }

  &__next-button {
    margin: $space-6 0;
  }

  &__error-message {
    font-size: $font-size-4;
    margin-bottom: $space-10;
  }
}
</style>
